import type { EfError } from '@kanbu/schema/contracts';
import { memo } from 'react';

export type EfErrorAlertProps = {
  errors: EfError[];
};

export const EfErrorAlert = memo(function EfErrorAlert({
  errors,
}: EfErrorAlertProps) {
  if (!Array.isArray(errors) || errors.length === 0) {
    return null;
  }

  return (
    <div className='mb-3 rounded-md bg-rose-900 p-3 text-left text-sm font-medium text-rose-50 animate-in fade-in zoom-in-90'>
      <ul className='list-disc space-y-1 pl-4'>
        {errors.map(error => (
          <li key={error.path}>{error.message}</li>
        ))}
      </ul>
    </div>
  );
});
