import { cn } from '@utima/ui';

interface StepCounterProps {
  totalSteps: number;
  currentStep: number;
  onStepClick: (step: number) => void;
}

function StepCounter({
  totalSteps,
  currentStep,
  onStepClick,
}: StepCounterProps) {
  return (
    <div className='flex h-[40px] items-center justify-center'>
      {Array.from({ length: totalSteps + 1 }, (_, index) => (
        <div
          key={index + 1}
          className={cn(
            'mx-2 flex items-center justify-center rounded-radius text-white delay-75 duration-150 ease-in-out hover:scale-110 transition-all',
            index <= currentStep ? 'cursor-pointer bg-primary' : 'bg-secondary',
            index === currentStep ? 'size-[35px]' : 'size-[30px]',
            currentStep > index && 'bg-success text-primary',
          )}
          onClick={() => index < currentStep && onStepClick(index)} // Only call if step is completed
        >
          {index + 1}
        </div>
      ))}
    </div>
  );
}

export default StepCounter;
