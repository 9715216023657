import { Loader2 } from 'lucide-react';

export type LoaderProps = {
  visible?: boolean;
  className?: string;
};

export function Loader({ visible = true, className }: LoaderProps) {
  if (!visible) {
    return null;
  }

  return (
    <div className='relative flex size-full h-fit items-center justify-center pt-12'>
      <Loader2 className='size-10 animate-spin' />
    </div>
  );
}
