import { useCallback, useEffect } from 'react';

import avatarImg from '@/assets/images/avatar.jpg';
import { SignIn } from '@/components/signIn/SignIn';
import { useBoundStore } from '@/store/store';

import { Bubble } from './components/bubble/Bubble';
import { Chat } from './components/chat/Chat';
import { ChatWindow } from './components/chat/ChatWindow';
import { MemberRegistration } from './components/registration/MemberRegistration';
import { NewMembership } from './components/registration/NewMembership';
import { NewMembershipConsent } from './components/registration/NewMembershipConsent';
import { NewMemberShipRecap } from './components/registration/NewMembershipRecap';
import { PaymentMembership } from './components/registration/PaymentMembership';
import { Route } from './components/route/Route';
import { Welcome } from './components/welcome/Welcome';
import { useEfitnessRefreshToken } from './hooks/useEfitnessRefreshToken';
import { hasPaymentParam } from './lib/utils';

export function Widget() {
  const { open, setUnread, pushRoute, user } = useBoundStore(state => ({
    open: state.open,
    setUnread: state.setUnread,
    pushRoute: state.pushRoute,
    user: state.user,
  }));

  useEffect(() => {
    if (user) {
      pushRoute('chat');
    } else {
      pushRoute('welcome');
    }
  }, [pushRoute, user]);

  // Subscribe to eFitness refresh token
  useEfitnessRefreshToken();

  const handleOpen = useCallback(() => {
    open();
    setUnread(0);
  }, [open, setUnread]);

  /**
   * Open chatbot when URL with param ff-chatbot-payment-finish is present
   * and redirect to the payment page
   */
  useEffect(() => {
    if (hasPaymentParam()) {
      open();
      pushRoute('chat');
    }
  }, [open, pushRoute]);

  return (
    <>
      <ChatWindow>
        <Route name='welcome'>
          <Welcome />
        </Route>
        <Route name='chat'>
          <Chat />
        </Route>
        <Route name='newMembership'>
          <NewMembership />
        </Route>
        <Route name='newMembershipConsent'>
          <NewMembershipConsent />
        </Route>
        <Route name='newMembershipRecap'>
          <NewMemberShipRecap />
        </Route>
        <Route name='membershipPayment'>
          <PaymentMembership />
        </Route>
        <Route name='memberRegistration'>
          <MemberRegistration />
        </Route>
        <Route name='signIn'>
          <SignIn />
        </Route>
      </ChatWindow>

      <Bubble src={avatarImg} onClick={handleOpen} />
    </>
  );
}
