export enum PaymentForm {
  Cash = 0,
  Wallet = 1,
  Card = 2,
  Transfer = 3,
  CreditCard = 4,
  Coupon = 6,
  BonusPoints = 7,
  OnlinePayment = 8,
  DirectDebit = 9,
  OuterSystem = 10,
  FreePass = 11,
  TerminalRecursion = 12,
  Mixed = 13,
  Vindication = 14,
  AmazonPay = 15,
  Voucher = 16,
}

export const PaymentFormHumanReadable = {
  0: 'Cash',
  1: 'Wallet',
  2: 'Card',
  3: 'Transfer',
  4: 'Credit Card',
  6: 'Coupon',
  7: 'Bonus Points',
  8: 'Online Payment',
  9: 'Direct Debit',
  10: 'Outer System',
  11: 'Free Pass',
  12: 'Terminal Recursion',
  13: 'Mixed',
  14: 'Vindication',
  15: 'Amazon Pay',
  16: 'Voucher',
};

export enum PeriodType {
  Day = 0,
  Week = 1,
  Month = 2,
  All = 3,
  FourWeeks = 4,
  TwoWeeks = 5,
  ThreeMonths = 6,
  Settlement = 7,
}

export const PeriodTypeHumanReadable = {
  0: 'Day',
  1: 'Week',
  2: 'Month',
  3: 'All',
  4: 'Four Weeks',
  5: 'Two Weeks',
  6: 'Three Months',
  7: 'Settlement',
};

export enum RenewalType {
  End = 0,
  Renew = 1,
  IndefiniteTime = 2,
  ContinueWithSelectedAgreement = 3,
}

export const RenewalTypeHumanReadable = {
  0: 'End',
  1: 'Renew',
  2: 'Indefinite Time',
  3: 'Continue with Selected Agreement',
};

export enum MembershipStatus {
  Pending = 0,
  PendingLive = 1,
  Accepted = 2,
  Cancelled = 3,
}

export const MembershipStatusHumanReadable = {
  0: 'Pending',
  1: 'Pending Live',
  2: 'Accepted',
  3: 'Cancelled',
};

export enum EFitnessProvider {
  Czechia = 'czechia',
  Slovakia = 'slovakia',
  Croatia = 'croatia',
}
