import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from '@utima/ui';
import { Form, type TypedFormState, Checkbox } from '@utima/ui-informed';
import { HTTPError } from 'ky';
import { useTranslation } from 'react-i18next';

import { aiCoreApi } from '@/services/aiCoreClient';
import { i18n } from '@/services/i18n';
import { membershipDefinitionsKeys } from '@/services/queryClient';
import { useBoundStore } from '@/store/store';

import { Loader } from '../loader/Loader';
import { Stack } from '../stack/Stack';

export function NewMembershipConsent() {
  const { t } = useTranslation();
  const { pushRoute, popRoute } = useBoundStore(state => ({
    pushRoute: state.pushRoute,
    popRoute: state.popRoute,
  }));

  const [registrationMemberData, setRegistrationMemberData] = useBoundStore(
    state => [state.registrationMemberData, state.setRegistrationMemberData],
  );

  const { data, isFetching } = useQuery({
    queryKey: membershipDefinitionsKeys.consents(),
    queryFn: async () => {
      return aiCoreApi.eFitness.membershipDefinitions.consents({
        clubId: registrationMemberData.clubId,
        id: registrationMemberData.membershipId,
      });
    },
    retry: 0,
    enabled: !!registrationMemberData,
  });

  const { mutateAsync, error } = useMutation({
    mutationFn: async (formState: TypedFormState<never>) => {
      setRegistrationMemberData({
        ...registrationMemberData,
        selectedConsentIds: Object.keys(formState.values).map(key =>
          Number(formState.values[key]),
        ),
      });

      pushRoute('newMembershipRecap');
    },
  });

  return (
    <Stack title={t('labels.consent')} onBack={() => popRoute()}>
      {isFetching ? (
        <Loader />
      ) : (
        <Form onSubmit={mutateAsync} className='flex flex-col gap-5'>
          {data?.map(consent => {
            const [czechPart, englishPart] = consent.content.split('/');
            const lng = i18n.language === 'cs' ? czechPart : englishPart;

            if (!lng) {
              return null;
            }

            return (
              <div key={consent.consentDefinitionId}>
                <Checkbox
                  name={consent.consentDefinitionId.toString()}
                  required={consent.required}
                  variant='success'
                  description={lng}
                  className='flex flex-row'
                  validate={value => {
                    if (consent.required && !value) {
                      return t('labels.required');
                    }
                  }}
                />
                {consent?.links?.length > 0 && (
                  <a
                    href={consent?.links[0].url}
                    target='_blank'
                    rel='noreferrer'
                    className='text-sm text-white underline'
                  >
                    {consent?.links[0].text}
                  </a>
                )}
              </div>
            );
          })}

          {error && (
            <p className='text-sm font-medium text-rose-600 animate-in fade-in slide-in-from-top'>
              {error instanceof HTTPError && error.response.status === 401
                ? t('loginForm.errors.401')
                : t('loginForm.errors.500')}
            </p>
          )}
          <Button size='lg' type='submit' className='mt-4'>
            {t('actions.continue')}
          </Button>
        </Form>
      )}
    </Stack>
  );
}
