import { z } from 'zod';

/**
 * eFitness Membership definitions
 */
export const efMembershipDefinitionSchema = z.object({
  id: z.number(),
  clubId: z.number(),
  name: z.string(),
  installmentPrice: z.number(),
  installmentsNumber: z.number(),
  validFrom: z.string().datetime(),
  validTo: z.string().datetime(),
  internetName: z.string(),
  internetDescription: z.string(),
  internetPriority: z.number(),
  hasService: z.boolean(),
  joinFee: z.number(),
  administrativeFee: z.number(),
  periodTime: z.number(),
  periodType: z.number(),
  paymentForm: z.number(),
  periodOfNotice: z.number(),
  periodOfNoticeType: z.number(),
  categoryId: z.number(),
  label: z.string(),
});

export type EfMembershipDefinition = z.infer<
  typeof efMembershipDefinitionSchema
>;

export const efMembershipDefinitionsResponseSchema = z.object({
  results: z.array(efMembershipDefinitionSchema),
});

export type EfMembershipDefinitionsResponse = z.infer<
  typeof efMembershipDefinitionsResponseSchema
>;

export const efIntMembershipDefinitionsResponseSchema = z.array(
  efMembershipDefinitionSchema,
);

export type EfIntMembershipDefinitionsResponse = z.infer<
  typeof efIntMembershipDefinitionsResponseSchema
>;

export const efConsentDefinitionSchema = z.object({
  consentDefinitionId: z.number(),
  content: z.string(),
  links: z.array(
    z.object({
      url: z.string(),
      text: z.string(),
    }),
  ),
  name: z.string(),
  required: z.boolean(),
  defaultChecked: z.boolean(),
  signatureRequired: z.boolean(),
});

export type EfConsentDefinition = z.infer<typeof efConsentDefinitionSchema>;

export const efConsentDefinitionsResponseSchema = z.object({
  results: z.array(efConsentDefinitionSchema),
});

export type EfConsentDefinitionsResponse = z.infer<
  typeof efConsentDefinitionsResponseSchema
>;

export const efIntConsentDefinitionsResponseSchema = z.array(
  efConsentDefinitionSchema,
);

export type EfIntConsentDefinitionsResponse = z.infer<
  typeof efIntConsentDefinitionsResponseSchema
>;
