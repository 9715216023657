import { Button } from '@utima/ui';
import { ChevronLeft } from 'lucide-react';
import { memo, type ReactNode } from 'react';

export type StackProps = {
  onBack?: () => void;
  title?: ReactNode;
  children: ReactNode;
};

export const Stack = memo(function Stack({
  children,
  title,
  onBack,
}: StackProps) {
  return (
    <div className='flex size-full grid-cols-1 flex-col'>
      <div className='flex flex-row items-center justify-between border-b border-primary px-5 py-3'>
        <div className='grid w-full grid-cols-9 items-center justify-center'>
          {onBack && (
            <Button
              size='icon-sm'
              icon={<ChevronLeft className='size-6' />}
              onClick={onBack}
            />
          )}
          {title && (
            <div className='col-span-7 text-center text-xl font-semibold'>
              {title}
            </div>
          )}
        </div>
      </div>
      <div className='h-fit overflow-y-auto p-5'>{children}</div>
    </div>
  );
});
