import { Language } from '@kanbu/schema/enums';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import zodCs from 'zod-i18n-map/locales/cs/zod.json';
import zodEn from 'zod-i18n-map/locales/en/zod.json';
import zodHr from 'zod-i18n-map/locales/hr-HR/zod.json';
import zodSk from 'zod-i18n-map/locales/sk/zod.json';

import cs from '../assets/locales/cs.json';
import en from '../assets/locales/en.json';
import hr from '../assets/locales/hr.json';
import sk from '../assets/locales/sk.json';

export const DEFAULT_NAMESPACE = 'glossary';
export const SUPPORTED_LANGUAGES = [
  Language.Czech,
  Language.English,
  Language.Croatian,
  Language.Slovak,
];

export interface I18nCsResources {
  glossary: typeof cs;
}

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: typeof DEFAULT_NAMESPACE;
    resources: I18nCsResources;
  }
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: Language.Czech,
    nonExplicitSupportedLngs: true,
    load: 'languageOnly',
    cleanCode: true,
    interpolation: {
      escapeValue: false,
    },
    defaultNS: DEFAULT_NAMESPACE,
    supportedLngs: SUPPORTED_LANGUAGES,
    detection: {
      order: ['path', 'subdomain', 'htmlTag', 'querystring'],
    },
    resources: {
      cs: {
        glossary: cs,
      },
      en: {
        glossary: en,
      },
      hr: {
        glossary: hr,
      },
      sk: {
        glossary: sk,
      },
    },
  });

i18n.addResourceBundle(Language.Czech, 'zod', zodCs);
i18n.addResourceBundle(Language.English, 'zod', zodEn);
i18n.addResourceBundle(Language.Croatian, 'zod', zodHr);
i18n.addResourceBundle(Language.Slovak, 'zod', zodSk);
z.setErrorMap(zodI18nMap);

export { i18n };
