import type { ChatUserType } from '@kanbu/schema';
import type { EfAuthResponse } from '@kanbu/schema/contracts';
import type { StateCreator } from 'zustand';

import { defaultQueryClient } from '@/services/queryClient';

import type { AppSlice } from './appSlice';
import type { RouterSlice } from './routerSlice';

export type User = Omit<
  ChatUserType,
  'organization' | 'threadsCount' | 'thread'
>;

export interface UserSlice {
  user: User | null;
  login: (
    user: User,
    accessToken?: string,
    eFitnessUser?: EfAuthResponse | null,
  ) => void;
  logout: () => void;
  accessToken?: string | null;
  setAccessToken: (token: string | null) => void;
  eFitnessUser: EfAuthResponse | null;
  setUser: (user: User) => void;
  registrationMemberData?: any;
  setRegistrationMemberData: (data: any) => void;
}

export const createUserSlice: StateCreator<
  UserSlice & RouterSlice & AppSlice,
  [],
  [],
  UserSlice
> = (set, get) => ({
  user: null,
  eFitnessUser: null,
  setAccessToken: token =>
    set({
      accessToken: token,
    }),
  login: (user, accessToken, eFitnessUser = null) =>
    set({
      user,
      accessToken: accessToken,
      eFitnessUser: eFitnessUser,
    }),
  logout: () => {
    // Clear query cache
    defaultQueryClient.clear();

    // Clear global state
    set({
      user: null,
      accessToken: null,
    });

    // Redirect to welcome route
    get().pushRoute('welcome');
  },
  setUser: user => set({ user }),
  setRegistrationMemberData: data => set({ registrationMemberData: data }),
});
