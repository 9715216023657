import { z } from 'zod';

export const baseIdentityEntitySchema = z.object({
  id: z.string().uuid(),
});

export const baseEntitySchema = baseIdentityEntitySchema.extend({
  createdAt: z.string(),
  updatedAt: z.string(),
});
