import { setComponentOverrides } from '@utima/ui/overrides';

/**
 * @utima/ui component overrides
 */
setComponentOverrides({
  button: {
    button: 'hover:scale-105 active:scale-100 font-bold',
    variants: {
      size: {
        lg: '!rounded-xl',
      },
    },
  },
  label: {},
  input: {
    variants: {
      size: {
        lg: '!rounded-xl',
      },
    },
  },
  checkbox: {
    checkbox: 'bg-white',
  },
  select: {
    label: 'text-primary',
    variants: {
      size: {
        lg: '!rounded-xl',
      },
    },
  },
});
