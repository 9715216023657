import type { FormState } from 'informed';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  type ReactNode,
} from 'react';

interface StepFormContextProps {
  currentStep: number;
  numberOfSteps: number;
  formState: FormState | undefined;
  isLastStep: boolean;
  setFormState: (state: FormState) => void;
  goToNextStep: () => void;
  goToStep: (step: number) => void;
  goToPreviousStep: () => void;
}

const MultipleStepFormContext = createContext<StepFormContextProps | undefined>(
  undefined,
);

interface StepFormProviderProps {
  children: ReactNode;
}

function MultipleStepFormProvider({ children }: StepFormProviderProps) {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [formState, setFormState] = useState<FormState | undefined>(undefined);

  const isLastStep = currentStep === React.Children.count(children);
  const numberOfSteps = React.Children.count(children);

  const goToStep = useCallback((step: number) => {
    setCurrentStep(step);
  }, []);

  const goToNextStep = useCallback(() => {
    if (isLastStep) {
      return;
    }

    setCurrentStep(prev => prev + 1);
  }, [isLastStep]);

  const goToPreviousStep = useCallback(
    () => setCurrentStep(prev => Math.max(prev - 1, 0)),
    [],
  );

  const memoValues = useMemo(
    () => ({
      currentStep,
      numberOfSteps,
      isLastStep,
      goToStep,
      goToNextStep,
      goToPreviousStep,
      setFormState,
      formState,
    }),
    [
      currentStep,
      numberOfSteps,
      formState,
      isLastStep,
      goToNextStep,
      goToPreviousStep,
      setFormState,
      goToStep,
    ],
  );

  return (
    <MultipleStepFormContext.Provider value={memoValues}>
      {children}
    </MultipleStepFormContext.Provider>
  );
}

export const useMultipleStepForm = (): StepFormContextProps => {
  const context = useContext(MultipleStepFormContext);
  if (!context)
    throw new Error(
      'useMultipleStepForm must be used within a MultipleStepFormProvider',
    );

  return context;
};

export default MultipleStepFormProvider;
