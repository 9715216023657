import { useCallback } from 'react';

import { useBoundStore } from '@/store/store';

export function useUser() {
  const user = useBoundStore(state => state.user);
  const eFitnessUser = useBoundStore(state => state.eFitnessUser);
  const login = useBoundStore(state => state.login);
  const logout = useBoundStore(state => state.logout);
  const setAccessToken = useBoundStore(state => state.setAccessToken);

  const isAuthenticated = useCallback(() => {
    return !!user;
  }, [user]);

  const isEfitnessAuthenticated = useCallback(() => {
    return !!eFitnessUser;
  }, [eFitnessUser]);

  return {
    user,
    eFitnessUser,
    login,
    logout,
    isAuthenticated,
    isEfitnessAuthenticated,
    setAccessToken,
  };
}
