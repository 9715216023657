import { z } from 'zod';

/**
 * Payments schemas & types
 */
export const efOnlinePaymentSchema = z.object({
  installmentIds: z.array(z.number()),
  chargeIds: z.array(z.number()).optional(),
  classReservationId: z.number().optional(),
  walletChargeValue: z.number().optional(),
  voucherId: z.number().optional(),
  returnUrl: z.string(),
});

export const efOnlinePaymentResponseSchema = z.object({
  redirectUrl: z.string(),
});

export type EfOnlinePaymentDTO = z.infer<typeof efOnlinePaymentSchema>;
export type EfOnlinePaymentResponse = z.infer<
  typeof efOnlinePaymentResponseSchema
>;
