import { ToolNames } from '@kanbu/schema/contracts';
import { ChatRole } from '@kanbu/schema/enums';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import avatarImg from '@/assets/images/avatar.jpg';
import { useChatConfig } from '@/contexts/ChatConfigProvider';
import type { ChatMessageItem } from '@/hooks/chatTypes';

import { MarkdownMessageContainer } from './components/MarkdownMessageContainer';
import { MessageContainer } from './components/MessageContainer';
import { MessageContent } from './components/MessageContent';
import { MessageFooter } from './components/MessageFooter';
import { MessagedHeader } from './components/MessageHeader';
import { MessageLayout } from './components/MessageLayout';
import { MembershipTool } from './tools/membershipTool/MembershipTool';
import { RagResultTool } from './tools/RagResultTool';
import { ActivityIndicator } from '../activityIndicator/ActivityIndicator';
import { Feedback } from '../feedback/Feedback';

export type AsistentMessageProps = {
  data?: ChatMessageItem;
  typing?: boolean;
};

const tools = {
  [ToolNames.RagResult]: RagResultTool,
  [ToolNames.Membership]: MembershipTool,
};

export const AsistentMessage = memo(function Message({
  data,
  typing,
}: AsistentMessageProps) {
  const { t } = useTranslation();
  const { chatbotConfig } = useChatConfig();

  const { createdAt, content, id, toolCalls } = data ?? {};
  const avatarSrc = chatbotConfig.systemAvatar || avatarImg;

  return (
    <MessageLayout role={ChatRole.Assistant}>
      <MessagedHeader>
        {chatbotConfig.systemName || t('labels.systemName')}
      </MessagedHeader>
      <MessageContent avatarSrc={avatarSrc}>
        {typing ? (
          <MessageContainer>
            <ActivityIndicator />
          </MessageContainer>
        ) : (
          <>
            {content && (
              <MarkdownMessageContainer>{content}</MarkdownMessageContainer>
            )}
            {toolCalls?.map(toolCall => {
              if (toolCall.type === 'text') {
                return (
                  <MarkdownMessageContainer key={toolCall.id}>
                    {toolCall.content}
                  </MarkdownMessageContainer>
                );
              }

              if (toolCall.type === 'tool') {
                const ToolComponent =
                  tools[toolCall.name as keyof typeof tools];

                if (!ToolComponent) {
                  return null;
                }

                return <ToolComponent key={toolCall.id} toolCall={toolCall} />;
              }

              return null;
            })}
          </>
        )}
      </MessageContent>
      <MessageFooter createdAt={createdAt}>
        <Feedback messageId={id} />
      </MessageFooter>
    </MessageLayout>
  );
});
