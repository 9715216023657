import { z } from 'zod';

/**
 * Utility function to add a clubId to a schema.
 */
export const withClubId = <T extends z.AnyZodObject>(
  schema?: T,
): z.ZodIntersection<z.ZodObject<{ clubId: z.ZodString }>, T> => {
  if (!schema) {
    return z.object({ clubId: z.string() }) as never;
  }

  return z.object({ clubId: z.string() }).merge(schema) as never;
};

export type WithClubId<T> = T & { clubId: string };

/**
 * Error types
 */
export const efErrorSchema = z.object({
  responseCode: z.number(),
  message: z.string(),
  path: z.string(),
});

export const efErrorResponseSchema = z.object({
  errors: z.array(efErrorSchema),
});

export type EfError = z.infer<typeof efErrorSchema>;
export type EfErrorResponse = z.infer<typeof efErrorResponseSchema>;
