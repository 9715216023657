import type { EFitnessProvider } from '@kanbu/schema/enums';
import { SelectPrimitive } from '@utima/ui';
import { Select } from '@utima/ui-informed';
import type { ComponentPropsWithoutRef } from 'react';

import { useChatConfig } from '@/contexts/ChatConfigProvider';

import { eFitnessClubs, type EFitnessClub } from './eFitnessClubs';

export interface ClubSelectProps
  extends ComponentPropsWithoutRef<typeof Select> {}

/**
 * Form factory clubs grouped by the provider and city.
 */
const groupedClubs = Object.entries(eFitnessClubs).reduce(
  (acc, current) => {
    const [provider, clubs] = current;
    const groupedClubs = Object.groupBy(clubs, c => c.city) as Record<
      string,
      EFitnessClub[]
    >;

    acc[provider as EFitnessProvider] = groupedClubs;

    return acc;
  },
  {} as Record<EFitnessProvider, Record<string, EFitnessClub[]>>,
);

export function ClubSelect({ ...restProps }: ClubSelectProps) {
  const { chatbotConfig } = useChatConfig();
  const { efitnessProvider } = chatbotConfig;
  const clubs = groupedClubs[efitnessProvider];

  return (
    <Select usePortal={false} {...restProps}>
      {Object.entries(clubs).map(([city, clubs]) => (
        <SelectPrimitive.Group key={city}>
          <SelectPrimitive.Label>{city}</SelectPrimitive.Label>
          {clubs.map(club => (
            <Select.Item key={club.id} value={club.id.toString()}>
              {club.displayName}
            </Select.Item>
          ))}
        </SelectPrimitive.Group>
      ))}
    </Select>
  );
}
