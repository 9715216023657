import type defaultConfig from '../../config/default.cjs';

import { version } from '../../package.json';

const envConfig = __KANBU_CHATBOT_CONFIG__ as any as typeof defaultConfig & {
  debug: boolean;
};

export const AppSettings = {
  ...envConfig,
  debug: document.cookie.includes('debug=true') || envConfig.debug,
  version,
};

/**
 * Used to identify the chatbot payment flow in the AI Core API.
 * When this param is present, we redirect user to the success page.
 */
export const API_PARAM_PAYMENT = 'ff-chatbot-payment-finish';
