import type { EfError } from '@kanbu/schema/contracts';
import { HTTPError } from '@toss/ky';
import { useCallback, useState } from 'react';

/**
 * Helper hook to handle errors from eFitness API. If the error
 * is not a efitness error, it will be set as an unknown error.
 */
export function useEfErrorHandler() {
  const [errors, setErrors] = useState<EfError[]>([]);

  const handleError = useCallback(async (error: unknown) => {
    console.error(error);

    if (error instanceof HTTPError) {
      const response = await error.response.json();

      if (typeof response !== 'object' || !response) {
        return setErrors([
          {
            responseCode: 500,
            message: 'Unknown error',
            path: '',
          },
        ]);
      }

      // Handle eFitness errors
      if ('errors' in response && Array.isArray(response.errors)) {
        return setErrors(response.errors as EfError[]);
      }

      // Handle our API errors
      if ('message' in response && 'statusCode' in response) {
        return setErrors([
          {
            responseCode: response.statusCode,
            message: response.message,
            path: '',
          },
        ]);
      }
    }

    return setErrors([
      {
        responseCode: 500,
        message: 'Unknown error',
        path: '',
      },
    ]);
  }, []);

  const resetErrors = useCallback(() => {
    setErrors([]);
  }, []);

  return { errors, handleError, resetErrors };
}
