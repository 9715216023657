import { z } from 'zod';

import { chatUserSchema } from '../chatUserContract';

/**
 * eFitness Auth schemas & types
 */
export const efAuthSchema = z
  .object({
    login: z.string().email(),
    password: z.string(),
  })
  .strict();

export const efAuthResponseSchema = z
  .object({
    accessToken: z.string(),
    tokenType: z.string(),
    refreshToken: z.string(),
    name: z.string(),
    id: z.string(),
    issued: z.string(),
    expires: z.string(),
    expiresIn: z.number(),
  })
  .strict();

export const efRefreshSchema = z
  .object({
    refreshToken: z.string(),
  })
  .strict();

export type EfAuthDTO = z.infer<typeof efAuthSchema>;
export type EfRefreshDTO = z.infer<typeof efRefreshSchema>;
export type EfAuthResponse = z.infer<typeof efAuthResponseSchema>;

/**
 * Internal API responses & types
 */
export const efIntAuthResponseSchema = z.object({
  user: chatUserSchema,
  token: z.string(),
  eFitness: efAuthResponseSchema,
});

export const efIntRefreshResponseSchema = z.object({
  user: chatUserSchema,
  token: z.string(),
  eFitness: efAuthResponseSchema,
});

export type EfIntAuthResponse = z.infer<typeof efIntAuthResponseSchema>;
export type EfIntRefreshResponse = z.infer<typeof efIntRefreshResponseSchema>;
