import { useQuery } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { useEffect } from 'react';

import { aiCoreApi } from '@/services/aiCoreClient';
import { eFitnessKeys } from '@/services/queryClient';

import { useUser } from './useUser';

const REFRESH_BUFFER = 60 * 60 * 4000; // 1 hour buffer before expiration
const POLLING_INTERVAL = 15 * 60 * 1000; // Check every 15 minutes

/**
 * Keeps eFitness access token fresh by refreshing it when it's about to expire
 * using the refresh token.
 */
export function useEfitnessRefreshToken() {
  const { eFitnessUser, isEfitnessAuthenticated, logout, login, user } =
    useUser();

  const { data } = useQuery({
    queryKey: [eFitnessKeys.refreshToken()],
    queryFn: async () => {
      if (!eFitnessUser?.refreshToken) {
        return null;
      }

      const expiresAt = new Date(`${eFitnessUser.expires}Z`);
      expiresAt.setSeconds(expiresAt.getSeconds() - REFRESH_BUFFER);
      const shouldRefresh = Date.now() > expiresAt.getTime();

      if (!shouldRefresh) {
        return null;
      }

      try {
        return await aiCoreApi.eFitness.auth.refresh(
          {
            clubId: user!.clubId!,
            refreshToken: eFitnessUser.refreshToken,
          },
          {
            retry: 0,
          },
        );
      } catch (error) {
        if (error instanceof HTTPError) {
          const response = await error.response.json();
          console.error(response);
        }

        logout();
      }
    },
    enabled: isEfitnessAuthenticated(),
    refetchInterval: POLLING_INTERVAL,
    retry: false,
  });

  // Update user login with new tokens and data
  useEffect(() => {
    if (!data) {
      return;
    }

    login(data.user, data.token, data.eFitness);
  }, [data, login]);
}
