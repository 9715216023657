import { z } from 'zod';

import {
  MembershipStatus,
  PaymentForm,
  PeriodType,
  RenewalType,
} from '../../eFitnessEnums';

/**
 * eFitness Memberships schemas & types
 */
export const efCreateMembershipSchema = z.object({
  membershipDefinitionId: z.number(),
  agreementFrom: z.string(),
  selectedConsentIds: z.array(z.number()),
  selectedServiceIds: z.array(z.number()),
  discountCoupon: z.string().nullish(),
});

export const efCreateMembershipResponseSchema = z.object({
  membershipId: z.number(),
  memberId: z.number(),
  paymentValue: z.number(),
  installmentIdsForPayment: z.array(z.number()),
  chargeIdsForPayment: z.array(z.number()),
});

export type EfCreateMembershipDTO = z.infer<typeof efCreateMembershipSchema>;
export type EfCreateMembershipResponseDTO = z.infer<
  typeof efCreateMembershipResponseSchema
>;

export const efIntCreateMembershipSchema = efCreateMembershipSchema.merge(
  z.object({
    chatId: z.string().uuid(),
    userInfo: z.object({
      id: z.string(),
      name: z.string(),
      email: z.string().email(),
      club: z.string(),
      membershipName: z.string(),
    }),
  }),
);

export type EfIntCreateMembershipDTO = z.infer<
  typeof efIntCreateMembershipSchema
>;

export const efMembershipSchema = z.object({
  membershipId: z.number(), // <int32>
  membershipDefinitionID: z.number().nullable(), // <int32> or null
  name: z.string().nullable(), // string or null
  memberId: z.number(), // <int32>
  clubId: z.number(), // <int32>
  signed: z.string().nullable(), // <date-time> or null
  from: z.string().nullable(), // <date-time> or null
  to: z.string().nullable(), // <date-time> or null
  isFrozen: z.boolean(), // Specifies if membership is frozen
  canFreeze: z.boolean(), // Specifies if membership can be frozen
  isRenunciated: z.boolean(), // Specifies if membership is renunciated
  isVindicated: z.boolean(), // Specifies if membership is in vindication
  canRenunciate: z.boolean(), // Specifies if membership can be renunciated
  canRenew: z.boolean(), // Specifies if membership can be renewed
  canCancelRenew: z.boolean(), // Specifies if membership renewal can be canceled
  hasServices: z.boolean(), // Specifies if membership has services
  isValid: z.boolean(), // Determines if membership is valid
  bankName: z.string().nullable(), // string or null
  iban: z.string().nullable(), // string or null
  bic: z.string().nullable(), // string or null
  paymentForm: z.nativeEnum(PaymentForm).nullable(), // Enum for payment form or null
  timeToRenew: z.number().nullable(), // Number of time periods to renew or null
  timeToRenewType: z.nativeEnum(PeriodType).nullable(), // Enum for period type or null
  timeRenewed: z.number().nullable(), // Number of time periods renewed or null
  timeRenewedType: z.nativeEnum(PeriodType).nullable(), // Enum for renewed period type or null
  renewalType: z.nativeEnum(RenewalType), // Enum for the agreement end type
  entryLimitPeriodType: z.nativeEnum(PeriodType).nullable(), // Enum for period type for entry limit or null
  maxEntries: z.number().nullable(), // Max allowed entries or null
  validEntries: z.number().nullable(), // Valid used entries or null
  invalidEntries: z.number().nullable(), // Invalid used entries or null
  periodType: z.nativeEnum(PeriodType).nullable(), // Enum for period type for limit entry or null
  periodTime: z.number().nullable(), // Period time for this membership or null
  status: z.nativeEnum(MembershipStatus), // Enum for membership status
  nextPaymentDate: z.string().nullable(), // <date-time> or null
});

export type EfMembership = z.infer<typeof efMembershipSchema>;

export const efMembershipResponse = z.object({
  results: z.array(efMembershipSchema),
  totalCount: z.number(),
  next: z.number().nullable(),
  previous: z.number().nullable(),
});

export type EfMembershipResponse = z.infer<typeof efMembershipResponse>;
