import { t } from 'i18next';
import { LogIn } from 'lucide-react';
import { memo, type MouseEvent } from 'react';

interface SignInProps {
  disabled?: boolean;
  onSubmit: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const SignInButton = memo(function SignIn({
  onSubmit,
  disabled = false,
}: SignInProps) {
  return (
    <div>
      <button
        type='button'
        className='flex items-center gap-2 rounded-lg bg-gray-50/20 p-1 px-2 text-secondary-fg transition-all enabled:hover:scale-105 enabled:hover:opacity-70 enabled:active:scale-100 disabled:cursor-not-allowed disabled:opacity-75'
        onClick={onSubmit}
        disabled={disabled}
      >
        <LogIn className='size-4' />
        <span className='text-sm'>{t('formFactory.signIn')}</span>
      </button>
    </div>
  );
});
