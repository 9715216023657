import type { EfOnlinePaymentDTO } from '@kanbu/schema/contracts';
import { useMutation } from '@tanstack/react-query';
import { Button } from '@utima/ui';
import { useTranslation } from 'react-i18next';

import { useEfErrorHandler } from '@/hooks/useEfErrorHandler';
import { getPaymentReturnUrl } from '@/lib/utils';
import { aiCoreApi } from '@/services/aiCoreClient';
import { useBoundStore } from '@/store/store';

import { EfErrorAlert } from '../alerts/EfErrorAlert';
import { Stack } from '../stack/Stack';

interface RegistrationMemberData {
  clubId: number;
  invoiceID: number[];
}

export function PaymentMembership() {
  const { t } = useTranslation();
  const { errors, handleError, resetErrors } = useEfErrorHandler();
  const { pushRoute } = useBoundStore(state => ({
    pushRoute: state.pushRoute,
  }));

  const [registrationMemberData] = useBoundStore(state => [
    state.registrationMemberData as RegistrationMemberData,
  ]);

  const paymentMutation = useMutation({
    mutationFn: async (args: EfOnlinePaymentDTO) => {
      return aiCoreApi.eFitness.payments.onlinePayment({
        ...args,
        clubId: registrationMemberData.clubId.toString(),
      });
    },
  });

  const handlePayment = async () => {
    resetErrors();

    try {
      const url = getPaymentReturnUrl();
      const { redirectUrl } = await paymentMutation.mutateAsync({
        installmentIds: registrationMemberData.invoiceID,
        returnUrl: url,
      });

      // Redirect to the payment page
      window.location.href = redirectUrl;
    } catch (err) {
      await handleError(err);
    }
  };

  return (
    <Stack
      title={t('membershipRegistration.payment')}
      onBack={() => pushRoute('chat')}
    >
      <EfErrorAlert errors={errors} />
      <p>{t('membershipRegistration.paymentMessage')}</p>
      <Button
        size='lg'
        type='submit'
        variant='success'
        className='mt-4 w-full'
        onClick={handlePayment}
      >
        {t('membershipRegistration.pay')}
      </Button>
    </Stack>
  );
}
