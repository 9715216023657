import { Button } from '@utima/ui';
import { Form, Input, type TypedFormState } from '@utima/ui-informed';
import './sandbox.css';
import type { FormApi } from 'informed';
import { useEffect, useRef, useState } from 'react';

import { AppSettings } from '@/constants/AppSettings';

export interface SandboxProps {
  renderApp: (params: { chatId: string }) => void;
}

interface FormState {
  chatId: string;
}

const HISTORY_KEY = 'sandbox-chat-ids';

export function Sandbox({ renderApp }: SandboxProps) {
  const formApi = useRef<FormApi>();
  const [initialValues, setInitialValues] = useState<FormState>();
  const [history, setHistory] = useState<string[]>(() =>
    JSON.parse(localStorage.getItem(HISTORY_KEY) || '[]'),
  );

  // Save history to localStorage
  useEffect(() => {
    localStorage.setItem(HISTORY_KEY, JSON.stringify(history));
  }, [history]);

  // Re-render app on mount
  useEffect(() => {
    // Create initialValues from query params
    const url = new URL(window.location.href);
    const values: FormState = {
      chatId: url.searchParams.get('chatId') || '',
    };

    setInitialValues(values);

    if (values.chatId) {
      renderApp({
        chatId: values.chatId,
      });
    }
  }, []);

  const handleSubmit = (form: TypedFormState<FormState>) => {
    const { values } = form;
    const { chatId } = values;

    renderApp({ chatId });
    setHistory(prev => [...new Set([...prev, chatId])]);

    // Update URL with query param
    const url = new URL(window.location.href);
    url.searchParams.set('chatId', chatId);
    window.history.pushState({}, '', url.toString());
  };

  // Show sandbox panel only when debug is true
  if (!AppSettings.debug) {
    return null;
  }

  return (
    <div className='form-factory-chatbot-app'>
      <div className='m-12 flex flex-col gap-4'>
        <div className='max-w-sm rounded-lg border border-border bg-white p-6 shadow-sm drop-shadow-sm'>
          <h2 className='mb-4 border-b border-border pb-2 text-sm font-bold uppercase'>
            history
          </h2>

          {history.length > 0 && (
            <div className='max-h-40 overflow-y-auto rounded-radius border border-border bg-gray-100 p-2 font-mono text-xs'>
              {history.map(history => (
                <div key={history}>{history}</div>
              ))}
            </div>
          )}
        </div>

        <div className='max-w-sm rounded-lg border border-border bg-white p-6 shadow-sm drop-shadow-sm'>
          <h2 className='mb-4 border-b border-border pb-2 text-sm font-bold uppercase'>
            Sandbox Configuration
          </h2>
          <Form
            formApiRef={formApi}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            className='flex flex-col gap-4'
          >
            <Input required name='chatId' type='text' label='Chat ID:' />
            <div className='grid w-full grid-cols-2 gap-2'>
              <Button
                variant='secondary'
                className='font-medium text-white'
                type='submit'
              >
                Create Chatbot
              </Button>
              <Button
                onClick={() => {
                  const url = new URL(window.location.href);
                  url.searchParams.set('chatId', initialValues?.chatId || '');
                  url.searchParams.delete('config');
                  window.history.pushState({}, '', url.toString());

                  formApi.current?.reset({
                    values: {},
                    resetValues: true,
                  });
                }}
                type='reset'
                className='font-medium text-white'
                variant='danger'
              >
                Reset
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Sandbox;
