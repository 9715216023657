import type { StructuredToolCall } from '@kanbu/schema';
import type { MembershipToolDef } from '@kanbu/schema/contracts';
import { memo } from 'react';
import type { PartialDeep } from 'type-fest';

import { MembershipLogin } from './components/MembershipLogin';
import { MembershipRegistration } from './components/MembershipRegistration';
import { MembershipStatus } from './components/MembershipStatus';
import { UserProfile } from './components/UserProfile';
import { MessageContainer } from '../../components/MessageContainer';
import { MessageMarkdown } from '../../components/MessageMarkdown';

export type MembershipToolProps = {
  toolCall: StructuredToolCall<PartialDeep<MembershipToolDef>>;
};

export const MembershipTool = memo(function MembershipTool({
  toolCall,
}: MembershipToolProps) {
  // Return early if we don't have args or type yet
  if (!toolCall.args?.type) {
    return null;
  }

  const args = toolCall.args as MembershipToolDef;

  return (
    <MessageContainer className='flex-col'>
      {args.type === 'membership_status' && args.membership_status && (
        <MembershipStatus status={args.membership_status} />
      )}

      {args.type === 'user_profile' && args.user_profile && (
        <UserProfile profile={args.user_profile} />
      )}

      {args.type === 'membership_registration' &&
        args.membership_registration && (
          <MembershipRegistration registration={args.membership_registration} />
        )}

      {args.type === 'membership_login' && args.membership_login && (
        <MembershipLogin login={args.membership_login} />
      )}

      {args.type === 'other' && args.other?.content && (
        <MessageMarkdown>{args.other.content}</MessageMarkdown>
      )}
    </MessageContainer>
  );
});
