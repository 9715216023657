import { API_PARAM_PAYMENT } from '@/constants/AppSettings';

/**
 * Checks if the payment param is present in the URL.
 */
export function hasPaymentParam(): boolean {
  const url = new URL(window.location.href);
  const paramPayment = url.searchParams.get(API_PARAM_PAYMENT);

  return !!paramPayment;
}

/**
 * Removes the payment param from the URL and replaces the state.
 */
export function removePaymentParam(): void {
  const url = new URL(window.location.href);
  url.searchParams.delete(API_PARAM_PAYMENT);

  window.history.replaceState({}, document.title, url.toString());
}

/**
 * Sets the payment param in the URL.
 */
export function getPaymentReturnUrl(): string {
  const url = new URL(window.location.toString());
  url.searchParams.append(API_PARAM_PAYMENT, 'true');

  return url.toString();
}
