import { z } from 'zod';

import { baseOrganizationEntitySchema } from './organizationContract';

export const registrationSchema = baseOrganizationEntitySchema.extend({
  email: z.string().email().max(320),
  name: z.string().max(140),
  membershipName: z.string().max(70),
  clubId: z.number().int().nonnegative(),
});

export const createRegistrationSchema = registrationSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    organization: true,
  })
  .strict();

export const updateRegistrationSchema = registrationSchema
  .partial()
  .required({
    id: true,
  })
  .strict();

export type RegistrationDTO = z.infer<typeof registrationSchema>;
export type CreateRegistrationDTO = z.infer<typeof createRegistrationSchema>;
export type UpdateRegistrationDTO = z.infer<typeof updateRegistrationSchema>;
