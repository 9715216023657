import { useBoundStore } from '@/store/store';

export function useNavigate() {
  const navigate = useBoundStore.getState().pushRoute;
  const history = useBoundStore.getState().history;
  const popRoute = useBoundStore.getState().popRoute;
  const route = useBoundStore.getState().route;

  return { navigate, history, popRoute, route };
}
