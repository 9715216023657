import { AnimatePresence, motion } from 'framer-motion';
import { memo, type ReactNode } from 'react';

import type { Routes } from '@/store/slices/routerSlice';
import { useBoundStore } from '@/store/store';

export type RouteProps = {
  name: Routes;
  children: ReactNode;
};

export const Route = memo(function Route({ name, children }: RouteProps) {
  const route = useBoundStore(state => state.route);

  if (route !== name) {
    return null;
  }

  return (
    <AnimatePresence mode='wait'>
      <motion.div
        className='h-full'
        key={name}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.2 }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
});
