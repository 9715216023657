import { z } from 'zod';

import { efAuthResponseSchema } from './eFitnessAuthContract';
import { EFitnessGender } from '../../enums';
import { chatUserSchema } from '../chatUserContract';

/**
 * POST member schema
 */
export const efCreateMemberSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  password: z.string(),
  email: z.string(),
  cellPhone: z.string(),
  countryPhoneCode: z.string(),
  gender: z.nativeEnum(EFitnessGender),
  birthday: z.string(),
  street: z.string(),
  city: z.string(),
  postalCode: z.string(),
});

export const efCreateMemberResponseSchema = z.object({
  memberId: z.number(),
  authorizationData: efAuthResponseSchema,
});

export type EfCreateMemberDTO = z.infer<typeof efCreateMemberSchema>;
export type EfCreateMemberResponse = z.infer<
  typeof efCreateMemberResponseSchema
>;

export const efIntCreateMemberResponseSchema = z.object({
  user: chatUserSchema,
  token: z.string(),
  eFitness: efAuthResponseSchema,
});

export type EfIntCreateMemberResponse = z.infer<
  typeof efIntCreateMemberResponseSchema
>;

/**
 * GET member schema
 */
export const efMemberSchema = z.object({
  memberId: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  personalIdentityNumber: z.string(),
  email: z.string(),
  street: z.string(),
  streetAdditionalInfo: z.string(),
  flatNumber: z.string(),
  postalCode: z.string(),
  city: z.string(),
  county: z.string(),
  carRegistrationNumber: z.string(),
  cellPhone: z.string(),
  countryPhoneCode: z.string(),
  identityDocumentType: z.number(),
  identityDocumentNumber: z.string(),
  gender: z.number(),
  birthday: z.string().datetime(),
  birthPlace: z.string(),
  bankName: z.string(),
  iban: z.string(),
  bic: z.string(),
  bankAccountOwnerName: z.string(),
  accountOwner: z.string(),
  taxIdentificationNumber: z.string(),
  countryOfResidence: z.string(),
  companyInvoiceData: z.object({
    companyName: z.optional(z.string()),
    companyCity: z.optional(z.string()),
    companyCounty: z.optional(z.string()),
    companyPostalCode: z.optional(z.string()),
    companyStreet: z.optional(z.string()),
    companyStreetAdditionalInfo: z.optional(z.string()),
    companyTaxIdentificationNumber: z.optional(z.string()),
    companyRemarks: z.optional(z.string()),
  }),
  personalPhoto: z.string(),
  isEmailVerified: z.boolean(),
  cardNumber: z.string(),
  pin: z.string(),
  isVindicated: z.boolean(),
  memberStatus: z.number(),
  walletBalance: z.number(),
  hasCreditCard: z.boolean(),
});

export type EfMember = z.infer<typeof efMemberSchema>;
