export enum Location {
  // Praha locations
  Andel = 895,
  Butovice = 1185,
  Eden = 1184,
  Harfa = 1186,
  Hostivar = 1048,
  Karlin = 896,
  Letnany = 2087,
  Palladium = 1187,
  Pankrac = 1760,
  Soho = 2086,
  Stodulky = 1042,
  VaclavskeNamesti = 1041,
  Vinohradska = 1043,
  Vrsovicka = 1047,

  // Brno locations
  Luzanky = 1912,
  Vlnena = 1911,

  // Ostrava location
  OstravaAvion = 1804,
}

export function getLocationName(id: number): string {
  switch (id) {
    case Location.Andel:
      return 'Praha Anděl';
    case Location.Butovice:
      return 'Praha Butovice';
    case Location.Eden:
      return 'Praha Eden';
    case Location.Harfa:
      return 'Praha Harfa';
    case Location.Hostivar:
      return 'Praha Hostivař';
    case Location.Karlin:
      return 'Praha Karlín';
    case Location.Letnany:
      return 'Praha Letňany';
    case Location.Palladium:
      return 'Praha Palladium';
    case Location.Pankrac:
      return 'Praha Pankrác';
    case Location.Soho:
      return 'Praha SO-HO';
    case Location.Stodulky:
      return 'Praha Stodůlky';
    case Location.VaclavskeNamesti:
      return 'Praha Václavské náměstí';
    case Location.Vinohradska:
      return 'Praha Vinohradská';
    case Location.Vrsovicka:
      return 'Praha Vršovická';
    case Location.Luzanky:
      return 'Brno Lužánky';
    case Location.Vlnena:
      return 'Brno Vlněna';
    case Location.OstravaAvion:
      return 'Ostrava Avion';

    default:
      return 'Unknown location';
  }
}
