import '@/services/i18n';

import { ErrorBoundary } from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { FormTranslationsContext } from '@utima/ui-informed';
import { lazy, StrictMode, Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './app.css?inline';
import { AppSettings } from './constants/AppSettings';
import { ChatConfigProvider } from './contexts/ChatConfigProvider';
import { defaultQueryClient } from './services/queryClient';
import { Widget } from './Widget';

const ReactQueryDevtools = lazy(() =>
  import('@tanstack/react-query-devtools').then(res => ({
    default: res.ReactQueryDevtools,
  })),
);

type AppProps = {
  chat: string | null | undefined;
};

export function App({ chat }: AppProps) {
  const { t } = useTranslation();
  const formTranslations = useMemo(
    () => ({
      labels: {
        optional: t('labels.optional'),
      },
      errors: {
        required: t('labels.required'),
      },
    }),
    [t],
  );

  if (!chat) {
    console.error('Missing chatId');

    return null;
  }

  return (
    <StrictMode>
      {/* .form-factory-chatbot-app is used for tailwind scoping */}
      <div
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className='form-factory-chatbot-app'
        data-kanbu-chat={chat}
        data-kanbu-version={AppSettings.version}
      >
        <ErrorBoundary>
          <QueryClientProvider client={defaultQueryClient}>
            <ChatConfigProvider chatId={chat}>
              <FormTranslationsContext.Provider value={formTranslations}>
                <div className='relative z-[999999] font-sans text-base text-foreground'>
                  {/* Handles style injection to shadow dom in Web Component */}
                  <style dangerouslySetInnerHTML={{ __html: styles }} />

                  {/* Chatbot widget */}
                  <Widget />
                </div>
                {AppSettings.debug && (
                  <Suspense fallback={null}>
                    <ReactQueryDevtools buttonPosition='bottom-left' />
                  </Suspense>
                )}
              </FormTranslationsContext.Provider>
            </ChatConfigProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </div>
    </StrictMode>
  );
}
